<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">แก้ไขผู้ใช้งาน</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            v-if="user"
            @submit="onSubmitUser"
            :validation-schema="userValidator"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชื่อ - นามสกุล</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="id" v-model="user.id" />
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ชื่อ - นามสกุล"
                    v-model="user.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชื่อผู้ใช้งาน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="username"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ชื่อผู้ใช้งาน"
                    v-model="user.username"
                    :readonly="true"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="username" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >E mail</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="email"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="E mail"
                    v-model="user.email"
                    :readonly="true"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >รหัสผ่าน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="password"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="password"
                    placeholder="รหัสผ่าน"
                    v-model="user.password"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >ยืนยัน รหัสผ่าน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="confirmpassword"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="password"
                    placeholder="ยืนยัน รหัสผ่าน"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="confirmpassword" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ระดับการเข้าถึง</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="roles"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    as="select"
                    multiple
                    v-model="userRole"
                  >
                    <option
                      v-for="role in data_roles"
                      :key="role.id"
                      :value="role.id"
                      :selected="userRole.indexOf(role.id) != -1"
                    >
                      {{ role.name }}
                    </option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="roles" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import useUsers from "@/core/services/api/users";
import useSweetalert from "@/core/helpers/sweetalert2";
import * as Yup from "yup";
export default defineComponent({
  name: "users-edit",
  components: {
    ErrorMessage,
    Field,
    Form,
  },

  setup() {
    const { getUser, user, updateUser, userRole, data_roles } = useUsers();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const route = useRoute();

    const userValidator = Yup.object().shape({
      name: Yup.string()
        .required("กรุณากรอก ชื่อ - นามสกุล")
        .label("ชื่อ - นามสกุล"),
      username: Yup.string()
        .min(4, "อย่างน้อย 4 ตัวขึ้นไป")
        .required("กรุณากรอก ชื่อผู้ใช้งาน")
        .label("ชื่อผู้ใช้งาน"),
      email: Yup.string().email().required("กรุณากรอก Email").label("Email"),
      password: Yup.string().min(6, "อย่างน้อย 6 ตัวขึ้นไป").label("รหัสผ่าน"),
      confirmpassword: Yup.string()
        .min(6, "อย่างน้อย 6 ตัวขึ้นไป")
        .oneOf([Yup.ref("password"), null], "รหัสผ่านไม่ตรงกัน")
        .label("ยืนยัน รหัสผ่าน")
        .when("password", {
          is: (password) => password,
          then: Yup.string().required("กรุณากรอก ยืนยัน รหัสผ่าน"),
        }),
      roles: Yup.array()
        .min(1, "กรุณากรอกเลือกระดับการเข้าถึงอย่างน้อย 1 การเข้าถึง")
        .required("กรุณากรอกเลือกระดับการเข้าถึง"),
    });

    const onSubmitUser = (values) => {
      Sconfirm("ยืนยัน แก้ไขผู้ใช้งาน", "question").then(async (result) => {
        if (result.isConfirmed) {
          if (submitButton.value) {
            // Activate indicator
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          await updateUser(values);
          SToast("success", "แก้ไข ผู้ใช้งาน สำเร็จ");
          submitButton.value?.removeAttribute("data-kt-indicator");
        }
      });
    };

    onMounted(() => {
      getUser(route.params.id);
      setCurrentPageBreadcrumbs("แก้ไขผู้ใช้งาน", ["ผู้ใช้งาน"]);
    });

    return {
      user,
      userRole,
      data_roles,
      userValidator,
      submitButton,
      onSubmitUser,
    };
  },
});
</script>

<style></style>
